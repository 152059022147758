import { Component } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MyAngularApp';
  canrefresh:boolean = true;

  menuClick(){
    console.log("product click"); 
    $('#menu').click();
  }


  constructor(private router: Router) {

    // router.events.subscribe( (event: Event) => {

    //     if (event instanceof NavigationStart) {
    //         // Show loading indicator
    //     }

    //     if (event instanceof NavigationEnd) {
    //       $('#menu').click();
    //     }

    //     if (event instanceof NavigationError) {
    //         // Hide loading indicator

    //         // Present error to user
    //         console.log(event.error);
    //     }
    // });
  }
}
